import Image from 'next/future/image';
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import { ReebeloConditions } from '@lambda/reebelo';
import Slider from '../../slider/Slider';
import { Breakpoints } from '@/settings/breakpoints';
import premiumBg from '@/public/icons/product/premium_bg.svg';
import ConditionTab from './ConditionTab';
import useCategoryConditions from '@/lib/use-category-conditions';
import { ParseMarkdown } from '@/components/markdown';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const breakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 1.1,
    spaceBetween: 12,
  },
  [Breakpoints.XXS]: {
    slidesPerView: 1.1,
    spaceBetween: 20,
  },
  [Breakpoints.XS]: {
    slidesPerView: 1.2,
    spaceBetween: 30,
  },
  [Breakpoints.MD]: {
    slidesPerView: 2.5,
    spaceBetween: 30,
  },
  [Breakpoints.LG]: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
};

const ConditionPopup = (props: {
  isOpen: boolean;
  activeCondition: string;
  onClose: () => void;
  categoryPk: string;
}) => {
  const { activeCondition, isOpen, categoryPk } = props;

  const conditionsContent = useCategoryConditions(categoryPk);

  const [currentActiveCondition, setSetCurrentActiveCondition] =
    useState(activeCondition);

  const activeIndex = Math.max(
    0,
    conditionsContent?.options
      .map((object) => object.name)
      .indexOf(currentActiveCondition) || 0,
  );

  const [selectedIndex, setSelectedIndex] = useState(activeIndex);

  useEffect(() => {
    setSelectedIndex(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    // reset back condition when popup open
    setSetCurrentActiveCondition(activeCondition);
  }, [isOpen]);

  const conditionImages = conditionsContent?.options[activeIndex]?.imageUrls;

  // determine slider preset using active index

  const sliderPreset = conditionImages && conditionImages.length > 0;

  const isPremiumSelected =
    conditionsContent?.options[activeIndex]?.name === ReebeloConditions.Premium;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-30"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-30"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-white lg:bg-gray-700"
            onClick={props.onClose}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full xl:w-1/2">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-full">
                  <div className="relative flex h-full flex-col bg-white">
                    <div className="px-4 py-4 shadow sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-bold leading-6 text-gray-700 xs:text-lg"
                        >
                          Condition Detail
                        </Dialog.Title>
                        <button
                          onClick={props.onClose}
                          type="button"
                          className="btn-close opacity-100 md:h-3 md:w-3"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div
                      className="relative flex-1 overflow-y-auto px-4 pt-6 sm:px-6"
                      // eslint-disable-next-line react/forbid-dom-props
                      style={
                        isPremiumSelected
                          ? {
                              background:
                                'linear-gradient(92.65deg, #222122 38.4%, #13505B 97.23%)',
                            }
                          : undefined
                      }
                    >
                      {conditionsContent?.options.map((condition, index) => (
                        <div
                          key={condition.name}
                          // eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/migration-from-tailwind-2
                          className={cn(
                            'z-10 h-full bg-white ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            {
                              '!bg-transparent': isPremiumSelected,
                            },
                            {
                              hidden: selectedIndex !== index,
                            },
                          )}
                        >
                          <ul className="h-full">
                            <li
                              key={condition.name}
                              className="relative inline-block h-full w-full"
                            >
                              <div className="flex h-full flex-col">
                                {sliderPreset && (
                                  <div
                                    className={cn({
                                      isPremium: isPremiumSelected,
                                    })}
                                  >
                                    {conditionImages &&
                                    conditionImages.length > 1 ? (
                                      <>
                                        <Slider
                                          breakpoints={breakpoints}
                                          hideArrow
                                          paginationDots
                                          lazyLoad
                                        >
                                          {conditionImages?.map((image, i) => (
                                            <div
                                              key={`image_${i}_${condition.name}`}
                                              className={cn(
                                                'h-full rounded-lg bg-[#EFF2F9]',
                                                {
                                                  '!bg-[#182022]':
                                                    isPremiumSelected,
                                                },
                                              )}
                                            >
                                              <Image
                                                src={getEdgeUrl(image)}
                                                alt={condition.name}
                                                width={500}
                                                height={450}
                                                className="swiper-lazy h-full w-full object-fill"
                                                loading="lazy"
                                                quality={100}
                                              />
                                              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                            </div>
                                          ))}
                                        </Slider>
                                      </>
                                    ) : (
                                      <div className="w-full pb-6">
                                        <div
                                          className={cn(
                                            'w-full rounded-lg bg-[#EFF2F9]',
                                            {
                                              '!bg-[#182022]':
                                                isPremiumSelected,
                                            },
                                          )}
                                        >
                                          <Image
                                            src={getEdgeUrl(conditionImages[0])}
                                            alt={condition.name}
                                            width={500}
                                            height={450}
                                            className="mx-auto h-full w-[85vw] object-contain xs:w-[77vw] md:w-[38vw] xl:w-[23vw] 2xl:w-[31vw]"
                                            quality={100}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                <ConditionTab
                                  newCondition={conditionsContent?.options}
                                  sliderPreset={sliderPreset ?? false}
                                  isPremiumSelected={isPremiumSelected}
                                  selectedIndex={selectedIndex}
                                  isOpen={isOpen}
                                  setSelectedIndex={(selected) =>
                                    setSelectedIndex(selected)
                                  }
                                  setSetCurrentActiveCondition={(
                                    conditionStr,
                                  ) =>
                                    setSetCurrentActiveCondition(conditionStr)
                                  }
                                />
                                <div
                                  className={cn(
                                    'flex w-full flex-col py-4 xs:py-6 lg:py-4',
                                    {
                                      'items-start justify-start': sliderPreset,
                                    },
                                    {
                                      'mt-[15vh] !h-full w-full items-center justify-start':
                                        !sliderPreset,
                                    },
                                  )}
                                >
                                  <h3
                                    className={cn(
                                      'mb-2 font-extrabold leading-5 xs:mb-3',
                                      {
                                        'text-lg xs:mb-4 xs:text-xl lg:mb-2 lg:text-base':
                                          sliderPreset,
                                      },
                                      {
                                        'mb-2 text-lg xxs:mb-4 xs:text-2xl':
                                          !sliderPreset,
                                      },
                                      {
                                        'text-white': isPremiumSelected,
                                      },
                                    )}
                                  >
                                    Condition Description
                                  </h3>
                                  <div
                                    className={cn(
                                      ' text-gray-700 lg:text-xs',
                                      {
                                        'text-left text-xs xs:text-sm':
                                          sliderPreset,
                                      },
                                      {
                                        'text-center text-xs xxs:text-sm':
                                          !sliderPreset,
                                      },
                                      {
                                        '!text-white': isPremiumSelected,
                                      },
                                    )}
                                  >
                                    <div className="mt-1">
                                      <ParseMarkdown
                                        markdown={condition.description}
                                        variables={{}}
                                        size="base"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {isPremiumSelected && (
                                  <div className="-mr-4 mt-auto flex justify-end sm:-mr-6">
                                    <Image
                                      src={premiumBg}
                                      alt="Premium Condition"
                                      priority={true}
                                      className="h-full w-[17vh] object-contain"
                                    />
                                  </div>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { ConditionPopup };
