import React from 'react';
import cn from 'classnames';

type ParseMarkdownProps = {
  markdown: string;
  variables: Record<string, string>;
  size: 'small' | 'base';
};

export const ParseMarkdown = ({
  markdown,
  variables,
  size,
}: ParseMarkdownProps) => {
  // Split lines while preserving empty lines for double `\n` detection
  const lines = markdown.split(/\n/g);

  return (
    <>
      {lines.map((line, index) => {
        // Add an extra line break for empty lines (from double `\n`)
        if (line.trim() === '') return <br key={`break-${index}`} />;

        if (line.startsWith('#')) {
          const match = line.match(/^#+/);
          const headerLevel = match ? match[0].length : 0;
          const text = line.substring(headerLevel).trim();

          const headerClass = cn({
            'font-semibold text-gray-700': headerLevel === 1,
            'text-lg xs:text-xl': headerLevel === 1 && size === 'base',
            'text-base xs:text-lg': headerLevel === 1 && size === 'small',
            'font-semibold mt-5 md:mt-8': headerLevel === 2,
            'text-sm xs:text-base': headerLevel === 2 && size === 'base',
            'text-xs xs:text-sm': headerLevel === 2 && size === 'small',
          });

          return React.createElement(
            `h${headerLevel + 1}`,
            { key: index, className: headerClass },
            text,
          );
        }

        let result = line.replace(
          /\*\*(.*?)\*\*/g, // Matches **bold text**
          (match, boldText) => `<strong>${boldText}</strong>`,
        );

        result = result.replace(
          /\[(.*?)\]\((.*?)\)/g,
          (match, linkText, linkHref) =>
            `<a className="underline" target="_blank" href="${
              linkText === 'Reebelo' ? window.location.origin : linkHref
            }">${linkText}</a>`,
        );

        Object.entries(variables).forEach(([key, value]) => {
          result = result.replace(new RegExp(`{{${key}}}`, 'g'), value);
        });

        return (
          <p
            className={cn({
              'text-sm': size === 'base',
              'text-xs': size === 'small',
            })}
            key={index}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: result }}
          />
        );
      })}
    </>
  );
};
