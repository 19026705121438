import useSWR from 'swr';
import type { ProductCategoryCondition } from '@lambda/apis/src/category/types';

export default function useCategoryConditions(categoryPk: string) {
  const response = useSWR<ProductCategoryCondition>(
    categoryPk ? `/api/conditions/${categoryPk}` : null,
  );

  return response.data;
}
